const car = [
    { id: 1,  name: 'Aceites', price: '₡105000' },
    { id: 2,  name: 'Bateria', price: '₡15000' },
    { id: 3,  name: 'Llantas', price: '₡45000' },
    { id: 3,  name: 'Fibras freno', price: '₡6500' },
    { id: 3,  name: 'Filtros de aire', price: '₡14500' },

    // Agrega más autos según sea necesario
  ];
  
  export default car;
  