
const productsbaby = [
    { id: 1, name: 'Pañales', price: '₡436000' },
    { id: 2, name: 'Leche', price: '₡360000' },
    { id: 3, name: 'Pediatra', price: '₡100000' },
    { id: 1, name: 'Talco', price: '₡15000' },
    { id: 2, name: 'Biberones', price: '₡15000' },
    { id: 3, name: 'Toallas humedas', price: '₡12800' },
  ];
  
  export default productsbaby;
  
